body, html {
  background: #FFF;
  font-family: 'Open Sans', Helvetica, Arial, sans-serif;
  // regular 400, semi 600, bold 700
  font-weight: 400;
  overflow-x: hidden;
}
h1, h2, h3, h4, h5, h6 {
  font-family: 'Playfair Display', serif;
  // bold 700
  font-weight: 700;
}
h2 {
  font-size: 60px;
  margin: 0;
  padding: 0;
  .line {
    height: 10px;
    width: 100px;
    margin-bottom: 48px;
  }
}
h3 {
  font-size: 42px;
  margin: 0;
  padding: 0;
}
.clear {
  clear: both;
}
.marginTop88 {
  margin-top: 88px !important;
}

header {
  height: 120px;
  padding: 16px;
  box-sizing: border-box;
  box-shadow: 3px 3px 6px rgba(0,0,0,.1);
  top: 0;
  left: 0;
  width: 100%;
  &.transparent {
    position: absolute;
    z-index: 99;
    background-color: transparent;
    color: #FFFFFF;
    nav {
      li a {
        color: #FFFFFF;
        &.active span {
          color: #FFFFFF;
          border-bottom-color: #FFFFFF;
        }
      }
    }
  }
  &.solid {
    position: fixed;
    background-color: #FFFFFF;
    color: #82ADD6;
    z-index: 97;
  }
}
.container {
  max-width: 1300px;
  margin: 0 auto;
  position: relative;
  > a {
    display: inline-block;
  }
}
.logo {
  height: calc(120px - 32px);
  width: auto;
  pointer-events: none;
}
nav {
  float: right;
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: table;
    height: calc(120px - 32px);
  }
  li {
    display: table-cell;
    vertical-align: bottom;
    a {
      font-size: 18px;
      color: #82ADD6;
      padding: 16px;
      bottom: 8px;
      position: relative;
      text-decoration: none;
      font-weight: 600;
      &.active span {
        font-weight: 700;
        color: #06397D;
        border-bottom: 5px solid #06397D;
      }
    }
  }
}
button {
  -webkit-appearance: none;
  appearance: none;
  border: none;
  background-color: #F5A701;
  color: #2F363D;
  font-size: 20px;
  font-weight: 700;
  padding: 12px 32px;
  border-radius: 3px;
  margin-top: 48px;
  cursor: pointer;
  font-family: 'Open Sans', Helvetica, Arial, sans-serif;
  &:hover {
    background-color: darken(#F5A701, 5);
  }
  &:active {
    background-color: lighten(#F5A701, 5);
  }
}

.Hero {
  height: 100vh;
  min-height: 400px;
  background-color: #2F363D;
  // background-image: url('/images/Hero_bkg.png');
  background-size: cover;
  position: relative;
  width: 100%;
  overflow: hidden;
  z-index: 98;
  > .container {
    position: absolute;
    width: 100%;
    z-index: 2;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    font-size: 2vmax;
    h1 {
      margin: 0 auto;
      // text-transform: uppercase;
      color: #FFF;
      box-sizing: border-box;
      padding: 0 16px;
    }
    span {
      white-space: nowrap;
    }
    .yellow {
      color: #F5A701;
    }
  }
  video {
    min-height: 100%;
    min-width: 100%;
    height: auto;
    width: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0.25;
  }
  #carousel {
    padding: 0px;
    display: inline-block;
    position: relative;
    text-align: center;
    vertical-align: top;

    -webkit-transition: width 0.25s linear;
    -moz-transition: width 0.25s linear;
    -ms-transition: width 0.25s linear;
    -o-transition: width 0.25s linear;
    transition: width 0.25s linear;

    > span {
      display: inline-block;
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      // width: 100%;
      -webkit-transition: opacity 0.25s linear;
      -moz-transition: opacity 0.25s linear;
      -ms-transition: opacity 0.25s linear;
      -o-transition: opacity 0.25s linear;
      transition: opacity 0.25s linear;
      &:first-child {
        opacity: 1;
        width: auto;
        position: relative;
      }
    }
  }
}

.Approach {
  .different, .look {
    color: #FFFFFF;
    overflow: hidden;
    position: relative;
    margin-top: 88px;
    h2 .line {
      background-color: #FFFFFF;
    }
    .container {
      .leftBorder {
        width: 999px;
        position: absolute;
        top: 0;
        right: 100%;
        height: 100%;
        background-color: #749EBD;
      }
      .rightBorder {
        width: 200px;
        position: absolute;
        top: -50%;
        left: calc(100% - 240px);
        height: 200%;
        background-color: #749EBD;
        transform: rotate(11deg);
        z-index: 0;
      }
    }
    .inner_container {
      padding: 80px 32px;
      box-sizing: border-box;
      background-color: #749EBD;
      max-width: calc(100% - 120px);
      position: relative;
      z-index: 1;
    }
    ul {
      list-style: none;
      display: flex;
      width: 100%;
      margin: 0;
      padding: 0;
      justify-content: space-between;
      li {
        width: 30%;
        text-align: left;
        font-size: 24px;
      }
    }
  }
  .look {
    .container {
      .leftBorder, .rightBorder {
        background-color: #F5A701;
      }
    }
    .inner_container {
      background-color: #F5A701;
    }
    ul {
      display: block;
      list-style: disc;
      margin-left: 28px;
      li {
        display: list-item;
        width: auto;
      }
    }
  }
  .sellers {
    margin-top: 88px;
    color: #015089;
    h2 {
      text-align: center;
      .line {
        background-color: #015089;
        margin-left: auto;
        margin-right: auto;
      }
    }
    img {
      width: 400px;
      height: auto;
      position: relative;
      z-index: 2;
      display: inline-block;
      vertical-align: bottom;
    }
    .testimonial {
      max-width: 700px;
      width: calc(100% - 400px);
      padding: 48px;
      box-sizing: border-box;
      display: inline-block;
      vertical-align: bottom;
      font-size: 24px;
      font-style: italic;
      position: relative;
      .from {
        margin-top: 40px;
        font-style: normal;
        .name {
          font-size: 28px;
          font-weight: 600;
        }
        .title {
          font-size: 20px;
        }
      }
    }
    .seller_one {
      margin-bottom: 24px;
      .testimonial {
        background-color: #A8C0B1;
        color: #FFFFFF;
        .rightContainer {
          height: 100%;
          position: absolute;
          width: 75px;
          left: 100%;
          overflow: hidden;
          top: 0;
          .rightBorder {
            width: 200px;
            position: absolute;
            top: -50%;
            left: calc(100% - 240px);
            height: 200%;
            background-color: #A8C0B1;
            transform: rotate(11deg);
            z-index: 0;
          }
        }
      }
    }
    .seller_two {
      float: right;
      .testimonial {
        background-color: #7B7B7B;
        color: #FFFFFF;
        .leftContainer {
          height: 100%;
          position: absolute;
          width: 150px;
          right: 100%;
          overflow: hidden;
          top: 0;
          .leftBorder {
            width: 200px;
            position: absolute;
            top: -50%;
            right: calc(100% - 285px);
            height: 200%;
            background-color: #7B7B7B;
            transform: rotate(24deg);
            z-index: 0;
          }
        }
      }
    }
  }
}

footer {
  display: table;
  margin-top: 88px;
  width: 100%;
  overflow: hidden;
  .inner_container {
    display: inline-block;
    width: 50%;
    vertical-align: bottom;
    position: absolute;
    bottom: 0;
    left: 0;
  }
  .copyright {
    padding: 72px 32px;
    box-sizing: border-box;
    background-color: #F6F6F6;
    position: relative;
    img {
      margin-bottom: 16px;
    }
    .leftBorder {
      width: 999px;
      position: absolute;
      top: 0;
      right: 100%;
      height: 100%;
      background-color: #F6F6F6;
    }
  }
  #contact-us {
    display: inline-block;
    width: 50%;
    box-sizing: border-box;
    padding: 24px 32px 48px 0;
    background-color: #CDDFF5;
    position: relative;
    float: right;
    z-index: 1;
    h3 {
      margin-bottom: 16px;
      color: #06397D;
    }
    form {
      width: 100%;
      ::-webkit-input-placeholder {
         font-style: italic;
         color: #959DA5;
      }
      :-moz-placeholder {
         font-style: italic;
         color: #959DA5;
      }
      ::-moz-placeholder {
         font-style: italic;
         color: #959DA5;
      }
      :-ms-input-placeholder {
         font-style: italic;
         color: #959DA5;
      }
    }
    .row {
      display: table;
      width: 100%;
    }
    input, textarea {
      box-sizing: border-box;
      font-family: 'Open Sans', Helvetica, Arial, sans-serif;
      -webkit-appearance: none;
      appearance: none;
      border: none;
      padding: 8px;
      margin: 0 0 16px;
      font-size: 20px;
    }
    input {
      display: table-cell;
      width: 100%;
      background: transparent;
      border-bottom: 1px solid #FFFFFF;
    }
    .spacer {
      width: 8%;
      display: table-cell;
    }
    textarea {
      width: 100%;
      resize: none;
    }
    .errors {
      margin: 0;
      color: #911F1E;
      text-align: right;
      p {
        margin: 0;
      }
    }
    button {
      margin-top: 16px;
      float: right;
    }
    .rightBorder {
      width: 999px;
      position: absolute;
      top: 0;
      left: 100%;
      height: 100%;
      background-color: #CDDFF5;
    }
    .leftContainer {
      height: 100%;
      position: absolute;
      width: 150px;
      right: 100%;
      overflow: hidden;
      top: 0;
      .leftBorder {
        width: 200px;
        position: absolute;
        top: -50%;
        right: calc(100% - 250px);
        height: 200%;
        background-color: #CDDFF5;
        transform: rotate(11deg);
        z-index: 0;
      }
    }
  }
}

.Team {
  padding-top: 120px;
  h2 {
    text-align: center;
    color: #015089;
    .line {
      background-color: #015089;
      margin-left: auto;
      margin-right: auto;
    }
  }
  .People {
    background-color: #F6F6F6;
    padding: 64px 0;
    .inner_container {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: flex-start;
      .person {
        padding: 0;
        background: none;
        font-weight: inherit;
        border-radius: 0;
        transition: opacity 500ms;
        margin: 0;
        width: 32%;
        outline: none;
        .image_wrapper {
          position: relative;
          box-sizing: border-box;
          // &:before {
          //   transition: width 500ms;
          //   content: "";
          //   width: 0;
          //   height: 100%;
          //   background: #749EBD;
          //   position: absolute;
          //   top: 0;
          //   left: 0;
          // }
        }
        img {
          box-shadow: 3px 3px 6px rgba(0,0,0,0.25);
          height: auto;
          width: 100%;
          vertical-align: top;
        }
        .who {
          text-align: center;
          margin-top: 16px;
          font-size: 24px;
          padding-bottom: 16px;
          .name {
            font-weight: 700;
            color: #015089;
          }
          .title {
            color: #000000;
          }
          .subtitle {
            font-size: 16px;
          }
        }
        // &.active {
        //   .image_wrapper:before {
        //     width: 15px;
        //   }
        // }
      }
      // &.active {
      //   .person {
      //     opacity: 0.5;
      //     &.active {
      //       opacity: 1;
      //     }
      //   }
      // }
    }
    .bio {
      padding: 0;
      background: rgba(255,255,255,0.8);
      z-index: 100;
      position: fixed;
      top: 0;
      left: 100%;
      width: 100%;
      height: 100vh;
      opacity: 0;
      -webkit-transition: all 0.35s linear;
      -moz-transition: all 0.35s linear;
      -ms-transition: all 0.35s linear;
      -o-transition: all 0.35s linear;
      transition: all 0.35s linear;
      .underlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        z-index: 1;
        -webkit-appearance: none;
        appearance: none;
        padding: 0;
        margin: 0;
        background: transparent;
      }
      &.active {
        opacity: 1;
        left: 0;
      }
      .close {
        position: absolute;
        // top: 50%;
        // transform: translateY(-50%);
        // padding: 12px 8px 12px 12px;
        // border-radius: 4px 0 0 4px;
        height: 36px;
        top: 0;
        padding: 8px;
        border-radius: 0 0 0 4px;
        right: 0;
        z-index: 9;
        margin: 0;
        background: #666;
        &:hover {
          background: #999;
        }
        img {
          width: auto;
          height: 20px;
          line-height: 20px;
          vertical-align: top;
          // height: 24px;
          // vertical-align: bottom;
        }
      }
    }
    .bio_container {
      background-color: #015089;
      height: 450px;
      overflow: hidden;
      position: relative;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      z-index: 2;
      .image_wrapper {
        width: 50%;
        background-size: cover;
        background-position: center center;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        img {
          height: 100%;
          width: auto;
        }
        .leftBorder {
          position: absolute;
          width: 150px;
          height: 200%;
          top: -50%;
          right: calc(100% - 45px);
          background: #015089;
          transform: rotate(11deg);
        }
      }
      .text_wrapper {
        width: 50%;
        float: right;
        font-size: 16px;
        color: #FFFFFF;
        background: #749EBD;
        position: relative;
        p:first-child {
          margin-top: 0;
        }
        p:last-child {
          margin-bottom: 0;
        }
        .text {
          position: relative;
          z-index: 1;
          padding: 24px;
          box-sizing: border-box;
          width: 100%;
          max-height: 100vh;
          overflow: auto;
          height: 450px;
        }
        .leftBorder {
          position: absolute;
          width: 150px;
          height: 200%;
          top: -50%;
          right: calc(100% - 105px);
          background: #749EBD;
          transform: rotate(11deg);
          z-index: 0;
        }
        .rightBorder {
          position: absolute;
          width: 150px;
          height: 200%;
          top: -50%;
          left: calc(100% - 105px);
          background: #749EBD;
          transform: rotate(11deg);
          z-index: 0;
        }
      }
    }
  }
  .Awards {
    padding: 64px 0 0;
    .inner_container {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      .award {
        height: 250px;
        width: auto;
        padding: 16px;
      }
    }
  }
}

.ReactCollapse--collapse {
  transition: height 500ms;
}


@media (max-width: 1025px) {
  footer {
    .inner_container {
      width: 100%;
      position: relative;
      .copyright {
        padding: 32px;
      }
    }
  }
}
@media (max-width: 768px) {
  h2 {
    font-size: 48px;
    .line {
      margin-bottom: 32px;
    }
  }
  h3 {
    font-size: 36px;
  }
  nav {
    li {
      a {
        padding: 8px;
        font-size: 16px;
      }
    }
  }
  button {
    font-size: 16px;
    padding: 12px 24px;
  }
  .Approach {
    .different, .look {
      .inner_container {
        padding: 48px 32px;
      }
      ul {
        display: inline-block;
        li {
          width: 100%;
          margin-bottom: 8px;
          font-size: 20px;
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
    .sellers {
      .testimonial {
        padding: 32px;
        font-size: 18px;
        width: calc(100% - 300px);
        .from {
          .name { font-size: 20px; }
          .title { font-size: 16px; }
        }
      }
      img {
        width: 300px;
      }
    }
  }
  .Team {
    .People {
      .bio_container {
        max-height: 100%;
        height: 100%;
        .container {
          height: 100%;
        }
        .image_wrapper {
          display: none;
        }
        .text_wrapper {
          width: 75%;
          height: 100%;
          .leftBorder {
            right: calc(100% - 88px);
          }
          .text {
            height: 100%;
          }
        }
      }
      .inner_container {
        .person {
          .who {
            padding: 0 16px;
            box-sizing: border-box;
            .name { font-size: 20px; }
            .title { font-size: 16px; }
            .subtitle { font-size: 14px; }
          }
        }
      }
    }
    .Awards {
      .inner_container {
        .award {
          height: 150px;
        }
      }
    }
  }
  footer {
    #contact-us {
      width: 100%;
      padding: 24px 32px 48px;
      input, textarea, button {
        font-size: 16px;
      }
    }
  }
}
@media (max-width: 480px) {
  h2 {
    font-size: 32px;
    .line {
      margin-bottom: 24px;
    }
  }
  h3 {
    font-size: 28px;
  }
  nav {
    ul {
      height: 88px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      li {
        a {
          bottom: auto;
          padding: 0;
        }
      }
    }
  }
  .Hero > .container {
    font-size: 2.5vmax;
  }
  .Approach {
    .sellers {
      margin-top: 48px;
    }
    .different, .look {
      margin-top: 48px;
      .container {
        .rightBorder {
          display: none;
        }
      }
      .inner_container {
        padding: 32px 24px;
        max-width: 100%;
      }
    }
    .sellers {
      .testimonial {
        padding: 24px;
        font-size: 18px;
        width: 100%;
        .from {
          margin-top: 16px;
        }
      }
      img {
        display: none;
      }
    }
  }
  .marginTop88 {
    margin-top: 48px !important;
  }
  .Team {
    .People {
      padding: 48px 0;
      .bio_container {
        .text_wrapper {
          width: 100%;
        }
      }
      .inner_container {
        padding: 0 24px;
        .person {
          width: 100%;
          padding-bottom: 24px;
        }
      }
    }
    .Awards {
      .inner_container {
        .award {
          height: 120px;
        }
      }
    }
  }
  footer {
    margin-top: 48px;
    .inner_container {
      width: 100%;
      position: relative;
      .copyright {
        padding: 16px 24px;
        font-size: 12px;
      }
    }
    #contact-us {
      width: 100%;
      padding: 16px 24px 24px;
      input, textarea, button {
        font-size: 14px;
        margin-bottom: 12px;
      }
      button {
        margin: 8px 0;
      }
    }
  }
}
